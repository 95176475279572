/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

// Images
import check from 'img/check.svg'
import checkBlue from 'img/check_blue.svg'

// Third Party
import { motion, AnimatePresence } from 'framer-motion'
import { wrap } from 'popmotion'

const Hero = styled.div`
  @media (min-width: 992px) {
    min-height: 250px;
  }

  @media (max-width: 991px) {
    min-height: 150px;
  }
`

const ImageBackground = styled(Image)``

const Content = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};
  font-size: ${props => props.theme.font.size['18']};
  font-weight: ${props => props.theme.font.weight.m};
  /* text-shadow: 0 0 4px rgba(0, 0, 0, .85); */
  line-height: 35px;

  & h1 {
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size['100']};
    line-height: 80px;
    padding-bottom: 20px;
  }

  & i, em {
    font-weight: ${props => props.theme.font.weight.s};
  }

  & li {
    list-style: none;
    position: relative;
    font-size: ${props => props.theme.font.size.l};
    font-weight: ${props => props.theme.font.weight.s};

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      left: -30px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      background-size: contain;
      background-repeat: no-repeat;

      ${props => props.title === 'sanitaire-installaties' && css`
        background-image: url(${checkBlue});
      `}

      ${props => props.title === 'klimaat-installaties' && css`
        background-image: url(${check});
      `}

      ${props => props.title === 'duurzame-installaties' && css`
        background-image: url(${check});
      `}  
    }
  }
`

const Fade = styled.div`
  ${props => props.title === 'sanitaire-installaties' && css`
    background-color: ${props.theme.color.face.contrast};
  `}

  ${props => props.title === 'klimaat-installaties' && css`
    background-color: ${props.theme.color.face.main};
  `}

  ${props => props.title === 'duurzame-installaties' && css`
    background-color: ${props.theme.color.face.secondary};
  `}  

  z-index: -1;

  ${ImageBackground} {
    max-height: 945px;
    min-height: 600px;
    height: 100%;
    width: 100%;
    opacity: 0.10;

    @media screen and (max-width: 768px) {
      max-height: 1200px;
    }
  }
`

const Background = styled.div`
  height: 945px;
  width: 100%;
  position: absolute;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    height: 1200px;
  }
`

const ContentRow = styled.div`
  padding: 50px 0 0 0;
`

const SlideImage = styled(Image)`
  width: 100%;
  height: 100%;
  max-height: 845px;
  background-color: ${props => props.theme.color.face.secondary};
  position: absolute !important;
  bottom: 0;
`

const SliderContainer = styled.div`
  padding-top: 100px;
  position: relative;
  overflow: hidden;
  max-height: 945px;
  max-width: 100%;
`

const Buttons = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const Button = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: 0 10px 0 10px;
  border: 1px solid ${props => props.theme.color.face.light};
  cursor: pointer;
  z-index: 2;

  ${props => props.selected ? css`
    background-color: ${props.theme.color.face.light};
  ` : css`
    background-color: transparent;
  `}
`

const Slider = styled(motion.div)`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
`

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      // x: 1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      // x: -1000,
      opacity: 0
    };
  }
};

const HeroDefault = ({ title, fields }) => {
  const [[page, direction], setPage] = useState([0, 0])

  const imageIndex = wrap(0, fields.images.length, page)

  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection])
  }

  const swipeConfidenceThreshold = 10000;

  const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity
  }

  return (
    <Hero>
      <Background className="row mx-0">
        <Fade title={title} className="col-md-6 px-0">
          <ImageBackground src={fields.background} aboveFold />
        </Fade>
        <SliderContainer className="col-md-6 px-0">
          <AnimatePresence initial={false} custom={direction}>
            <Slider
              key={page}
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              className="h-100"
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 }
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);
    
                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}
            >
              <SlideImage src={fields.images[imageIndex].image} aboveFold />
            </Slider>
          </AnimatePresence>
          <Buttons>
            {fields.images.map((i, index) => (
              <Button selected={imageIndex === index} onClick={() => paginate(index - imageIndex)} />
            ))}
          </Buttons>
        </SliderContainer>
      </Background>
      <div className="container py-5">
        <ContentRow className="row">
          <div className="col-md-6 pt-4 pt-md-5 pr-md-5 pb-md-0">
            <Content title={title} content={fields.description} />
          </div>
        </ContentRow>
      </div>
    </Hero>
  )
}

export default HeroDefault