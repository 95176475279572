/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroService from 'components/elements/services/HeroService'
import Referenties from 'components/elements/Referenties'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'


const PageTemplate = ({
  data: {
    page: { slug, path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout altLogo={slug === 'sanitaire-installaties'}>
      <SEO yoast={yoast} path={path} />
      
      <HeroService title={slug} fields={acf.banner} />

      <div className="py-5" />
      <div className="py-5" />

      <div className="d-lg-none d-block" style={{ height: '200px' }} />

      <div className="container">
        <Referenties />
      </div>

      <div className="py-2" />
      <div className="py-5" />

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      slug

      acf {
        banner {
          description
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          images {
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
